/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif; /* Set a default font */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background: url('../src/assets/bg3.jpg') no-repeat center center fixed; /* Set your local background image */
  background-size: cover; /* Cover the entire page */
  z-index: -1; /* Ensure it is behind other elements */
}

* {
  box-sizing: border-box;
}

/* Main container and sections */
.main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Hide overflow by default */
  position: relative; /* Required for z-index to work */
  z-index: 1; /* Ensure the video is above the background */
}

/* Card styling */
.card {
  max-width: 100%; /* Ensure card does not exceed viewport width */
  margin: auto; /* Center align the card */
  padding: 1rem; /* Add padding as needed */
}

/* Scrollbar Styles */

/* WebKit browsers (Chrome, Safari) */
.container::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

.container::-webkit-scrollbar-track {
  background: transparent; /* Transparent background for the scrollbar track */
}

.container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent color for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  transition: background 0.3s ease; /* Smooth transition for color change */
}

.container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5); /* Darker color when hovering */
}

/* Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Thumb and track colors */
}

/* Show scrollbar only while scrolling */
.container {
  overflow: auto; /* Enable scrollbars */
}

/* App Header Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .main-section {
    /* Adjust styles for smaller screens */
  }
}

@media (min-width: 769px) {
  .main-section {
    /* Adjust styles for larger screens */
  }
}

/* In your component-specific CSS */
.container {
  overflow: auto; /* Ensure scrollbars appear */
  /* Additional scrollbar styling */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif; /* Ensure headings also use Montserrat */
}

.navbar-links a {
  font-family: 'Montserrat', sans-serif; /* Navbar links */
}

.service-title {
  font-family: 'Montserrat', sans-serif; /* Service card titles */
}

.service-description {
  font-family: 'Montserrat', sans-serif; /* Service card descriptions */
}

/* Add more specific styles as needed for other text elements */

/* General footer styles */
footer {
  padding: 40px;
  color: #ffffff1d;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-left, .footer-middle, .footer-right {
  flex: 1;
  min-width: 250px;
}

/* Glass effect */
footer {
  background: rgba(255, 255, 255, 0.012);
  backdrop-filter: blur(50px);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}

.footer-left p {
  color: #000;
  margin: 0;
}

h3 {
  color: #000;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li a {
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}

/* Social icons styling */
.social-icons a {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

/* Latest News Section */
.news-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.news-item img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 8px;
}

.news-item p {
  color: #000;
  margin: 0;
}

.news-item small {
  display: block;
  font-size: 12px;
  color: #777;
}

/* Footer bottom */
.footer-bottom {
  text-align: center;
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }
}

/* General footer styles */
footer {
  padding: 20px 40px;
  color: #ffffff18;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left, .footer-right {
  flex: 1;
}

.footer-left p {
  color: #000;
  margin: 10px 0;
}

.footer-right p {
  color: #000;
  margin: 5px 0;
}

h3 {
  color: #000;
  margin-bottom: 10px;
}

.social-icons a {
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
}

/* Footer bottom */
.footer-bottom {
  text-align: center;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  margin-top: 20px;
}
