.map-container {
  position: relative;
}

.new-card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  background-image: linear-gradient(174.2deg, rgba(255, 244, 228, 0.7) 7.1%, rgba(240, 246, 238, 0.7) 67.4%); /* Reduced opacity */
  backdrop-filter: blur(5px); /* Frosted glass effect */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0); /* Optional: Add a subtle border for definition */
  
}


.new-card h2 {
  font-size: 35px;
  font-family: 'Orbitron', sans-serif;
  background-image: linear-gradient(45deg, rgba(255, 0, 150, 1), rgba(255, 140, 0, 1)); /* Color change on hover */
  -webkit-background-clip: text; /* Clips the background to the text */
  -webkit-text-fill-color: transparent; /* Makes the text fill transparent */
  text-transform: uppercase;
  margin-bottom: 10px;
}

.new-card .count {
  font-size: 58px;
  font-weight: bold;
  font-family: 'Orbitron', sans-serif;
  background-image: linear-gradient(45deg, rgba(255, 0, 150, 1), rgba(255, 140, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background-image 0.4s ease-out, transform 0.4s ease-out;
  animation: pulse 2s infinite; /* Initial pulse animation */
}

.new-card:hover .count {
  background-image: linear-gradient(109.6deg, rgba(96,221,142,1) 11.2%, rgba(24,138,141,1) 91.1%);
  transform: scale(1.1);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}


.new-card .count {
  font-size: 58px;
  font-weight: bold;
  font-family: 'Orbitron', sans-serif;
  background-image: linear-gradient(45deg, rgba(255, 0, 150, 1), rgba(255, 140, 0, 1)); /* Color change on hover */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background-image 0.4s ease-out, transform 0.4s ease-out; /* Transition for smooth animation */
}

.new-card:hover .count {
  background-image: linear-gradient( 109.6deg,  rgba(96,221,142,1) 11.2%, rgba(24,138,141,1) 91.1% );
  transform: scale(1.1); /* Slightly larger on hover */
}

.new-card .count:hover {
  animation: none; /* Reset animation when hovered to recount */
}

.new-card .count-animation {
  animation: pulse 2s infinite; /* Count element pulse animation */
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}
