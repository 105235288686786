/* src/components/TeamSection.css */

.team-section {
  padding: 80px;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.153);
  color: white;
}

.title-section {
  margin-bottom: 40px;
}

.team-title {
  font-family: 'Orbitron', sans-serif;
  background-image: linear-gradient(
    109.6deg,
    rgba(9, 9, 121, 1) 11.2%,
    rgba(144, 6, 161, 1) 53.7%,
    rgba(0, 212, 255, 1) 100.2%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  margin-bottom: 40px;
}

.team-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5%;
}

.team-card {
  position: relative;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Align content to the left */
  max-width: 300px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease-in-out;
  overflow: hidden;
}

.team-photo {
  border-radius: 15px;
  width: 100%;
  height: 350px; /* Tall image height */
  object-fit: cover;
  margin-bottom: 10px;
  transition: filter 0.3s ease, transform 0.3s ease;
}

.team-card::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgba(255, 165, 0, 0.5) 0%,
    rgba(255, 69, 0, 0.5) 100%
  );
  opacity: 0;
  transform: scale(1.2);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: -1;
}

.team-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(255, 165, 0, 0.7);
}

.team-card:hover::before {
  opacity: 1;
  transform: scale(1);
}

.team-card:hover .team-photo {
  filter: brightness(1.1) contrast(1.0);
  transform: scale(1.05);
}

.name {
  font-family: 'Michroma', sans-serif;
  font-size: 1.125em;
  margin: 5px 0;
  background-image: radial-gradient(
    circle farthest-corner at 17.1% 22.8%,
    rgba(226, 24, 24, 1) 0%,
    rgba(160, 6, 6, 1) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;  /* Left-align text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-image 0.3s ease;
}

.team-card:hover .name {
  background-image: linear-gradient( 63.1deg,  rgba(5,23,111,1) 16.4%, rgba(24,95,240,1) 64.5% );
}

.role-title {
  font-family: 'Michroma', sans-serif;
  font-size: 1vw;
  margin-top: 5px;
  background-image: linear-gradient(
    111.4deg,
    rgba(7, 7, 9, 1) 6.5%,
    rgba(27, 24, 113, 1) 93.2%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left; /* Left-align role title */
}

.social-icons {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.social-icon img {
  width: 20px;
  height: 20px;
}

.founder-card,
.ceo-card,
.cofounder-card {
  background-image: radial-gradient(
    circle 1224px at 10.6% 8.8%,
    rgba(255, 255, 255, 0.149) 0%,
    rgba(0, 191, 255, 0.473) 100.2%
  );
}

@media (max-width: 768px) {
  .team-title {
    font-size: 2.5rem;
  }

  .team-photo {
    height: 100%; /* Slightly smaller for mobile */
  }

  .team-card {
    width: 80%;
    max-width: 600px;
    margin-bottom: 20px;
  }

  .name {
    font-size: 1.0rem;
  }

  .role-title {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .team-section{
    padding: 10px;
  }
  
  .team-title {
    font-size: 2.5rem;
  }

  .team-card {
    width: 80%;
    max-width: 600px;
    margin-bottom: 20px;
  }

  .name {
    font-size: 0.8rem;
  }

  .role-title {
    font-size: 0.6rem;
  }

  .team-photo {
    height: 250px;
  }
}
