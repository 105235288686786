.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.06); /* Frosted glass effect */
  backdrop-filter: blur(20px);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Michroma', sans-serif;
  color: black;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.social-media-card {
  background-image: linear-gradient(109.6deg, rgba(112,246,255,0.33) 11.2%, rgba(221,108,241,0.26) 42%, rgba(229,106,253,0.71) 71.5%, rgba(123,183,253,1) 100.2%);
  border-radius: 10px;
  padding: 1rem;
  width: 25%;
  display: flex;
  font-family: "Michroma", sans-serif; /* Ensure all text uses Michroma font */
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centering content vertically */
}

.social-media-card h3 {
  font-family: "Michroma", sans-serif; /* Ensuring the heading uses Michroma font */
  font-size: 1.5rem; /* Adjust the font size as needed */
  margin: 1rem 0; /* Add margin for spacing */
}


.qr-code img {
  width: 100%;
  max-width: 150px;
  border-radius: 10px;
  margin-bottom: 1rem; /* Adds space below QR code */
  position: relative;
  align-items: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows icons to wrap in smaller screens */
  margin-top: 0.5rem; /* Adds space between title and icons */
}

.social-icons a {
  margin: 0.5rem; /* Adds space around icons */
}

.social-icons img {
  width: 40px; /* Set a base size for the icons */
  height: 40px; /* Maintain aspect ratio */
  transition: transform 0.3s; /* Smooth scaling effect */
}

.social-icons img:hover {
  transform: scale(1.2); /* Scale up on hover */
}

.footer-content {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.footer-column {
  width: 30%;
}

.footer-column h4 {
  font-family: 'Michroma', sans-serif;
  font-size: 1.5rem;
  background-image: linear-gradient(45deg, rgba(255, 0, 150, 1), rgba(255, 140, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-content a {
  font-family: 'Michroma', sans-serif; /* Using Michroma font */
  color: black; /* Black text color */
  text-decoration: none; /* Remove underline */
}

.footer-content a:hover {
  text-decoration: underline; /* Optional: underline on hover */
}

.copyright-card {
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  background-image: linear-gradient(109.6deg, rgba(112,246,255,0.33) 11.2%, rgba(221,108,241,0.26) 42%, rgba(229,106,253,0.71) 71.5%, rgba(123,183,253,1) 100.2%);
  border-radius: 10px;
  text-align: center;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .social-media-card, .footer-content, .copyright-card {
    width: 90%;
    margin-bottom: 1rem;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    width: 100%;
    margin-bottom: 1rem;
  }
}
