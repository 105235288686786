/* Container for design views - full-screen layout */
.design-views-container {
  position: relative;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  overflow: hidden; /* Hide overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 10px; /* Add equal padding to the top and bottom */
  box-sizing: border-box; /* Ensure padding is included in the height calculation */
  background-color: #f4f4f925; /* Soft background for less eye strain */
}

/* Horizontal scroll for design views */
.design-views-scroll {
  display: flex; /* Align children in a row */
  height: 100%; /* Full height of container */
  width: 100%; /* Full width */
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-behavior: smooth; /* Smooth scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari, Opera) */
.design-views-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Card styles - frosted glass effect */
.design-card {
  flex: 0 0 auto; /* Allow the card to size based on content */
  display: flex; /* Center content with Flexbox */
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.019); /* White with 25% opacity */
  backdrop-filter: blur(20px) saturate(150%); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(20px) saturate(150%); /* Safari support */
  border-radius: 16px; /* Rounded corners */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15); /* Soft shadow effect */
  margin: 0 20px; /* Space between cards */
  padding: 20px;
  transition: transform 0.3s ease-in-out; /* Smooth hover effect */
  max-width: 90vw; /* Card adapts to screen size */
  max-height: calc(100% - 80px); /* Maintain top and bottom padding symmetry */
}

/* Image inside each card */
.design-image {
  width: 100%; /* Ensure image fills the card width */
  height: auto; /* Maintain aspect ratio */
  max-width: 80vw; /* Scale image size based on screen width */
  max-height: 75vh; /* Scale image size based on screen height */
  object-fit: cover; /* Ensure the image covers the card area */
  border-radius: 16px; /* Rounded corners for the image */
}

/* Hover effect for card */
.design-card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Responsive styling for tablets */
@media (max-width: 768px) {
  .design-card {
    margin: 0 10px; /* Reduced margin for smaller screens */
    max-width: 85vw;
    max-height: 70vh;
  }

  .design-image {
    max-width: 85vw;
    max-height: 65vh;
  }
}

/* Responsive styling for mobile devices */
@media (max-width: 480px) {
  .design-views-container {
    padding: 20px 10px; /* Adjust padding for mobile */
  }

  .design-card {
    margin: 0 5px;
    max-width: 95vw;
    max-height: 60vh;
  }

  .design-image {
    max-width: 95vw;
    max-height: 55vh;
  }
}
