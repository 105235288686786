/* Ensure no global styles are hiding the scrollbars */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

* {
  box-sizing: border-box;
}

/* Scrollbar Styles */

/* WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Transparent background for the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0); /* Semi-transparent color for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  transition: background 0.3s ease; /* Smooth transition for color change */
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2); /* Darker color when hovering */
}

/* Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.534) transparent; /* Thumb and track colors */
}
